import React from "react";
import { Navigation } from "../components/Navigation";
import Footer from "../components/Footer";
import "./Privacy.css";

export const Privacy = () => {
  return (
    <div className="page-container">
      <div id="wraptext" className="content">
        <div id="privacy">
          <h2>Privacy Policy</h2>
          <p>
            This Privacy Policy describes how we collect, use, and share
            information about you when you visit our website. By using our
            website, you agree to the terms of this Privacy Policy.
          </p>
          <h3>Information we collect:</h3>
          <ul>
            <li>
              We may collect your name and email address when you contact us
              through the contact form on our website.
            </li>
            <li>
              We collect this information solely for the purpose of contacting you
              to respond to your inquiry or request for information.
            </li>
            <li>
              We will not share your name or email address with any third parties
              without your consent.
            </li>
          </ul>
          <h3>How we use your information:</h3>
          <ul>
            <li>
              We use the information you provide through the contact form on our
              website to respond to your inquiry or request for information.
            </li>
          </ul>
          <h3>Security:</h3>
          <ul>
            <li>
              We take reasonable steps to protect your personal information from
              unauthorized access, disclosure, or alteration.
            </li>
            <li>
              However, we cannot guarantee the security of your personal
              information transmitted to our website, and any transmission is at
              your own risk.
            </li>
          </ul>
          <h3>Changes to this Privacy Policy:</h3>
          <ul>
            <li>
              We may update this Privacy Policy from time to time. If we make
              material changes, we will notify you by email or by posting a notice
              on our website prior to the change becoming effective.
            </li>
            <li>Revised on April 18, 2023</li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};