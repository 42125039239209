
import React from "react";
import "./Footer.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { HashLink as Link } from "react-router-hash-link";


function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <ul className="footer-nav">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/services">Services</Link></li>
          <li><Link to="/contact">Contact</Link></li>
          {/* Add more links as needed */}
        </ul>
        <div className="social-links">
          <a
            href="https://www.linkedin.com/company/enexsub/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin"></i>
          </a>
          {/* Add more social links as needed */}
        </div>
        <p>
          &copy; 2023 ENEXSUB.{" "}
          <Link to="/privacy">Privacy Policy</Link>
        </p>
      </div>
    </footer>
  );
}

export default Footer;