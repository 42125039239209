import { Route, Routes } from 'react-router-dom';
import { Navigation } from './components/Navigation'; // Adjust path if necessary
import './App.css';
import { Contact } from './pages/Contact';
import Home from './pages/Home'; // Changed to default import
import { Privacy } from './pages/Privacy';
import Services from './pages/Services'; // Import Services

function App() {
  return (
    <div className="App">
      <Navigation /> {/* Include the Navigation component */}
      <Routes>
        <Route path="/" element={<Home />} /> {/* Use default import */}
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/services" element={<Services />} /> {/* Add route for Services */}
      </Routes>
    </div>
  );
}

export default App;