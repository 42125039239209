import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";

// npm i @emailjs/browser

const initialValues = {
  user_name: "",
  user_email: "",
  message: "",
};

const ContactForm = () => {
  const [formData, setFormData] = useState(initialValues);
  console.log(formData);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const disabledSend = Object.values(formData).some((value) => !Boolean(value));
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_9n67j33",
        "template_o1kb25x",
        form.current,
        "NYTAIqaqH8mKt-vvh"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
        },
        (error) => {
          console.log(error.text);
        }
      );

    document.getElementById("submit-button").disabled = true;
    document.getElementById(
      "submit-button"
    ).value = `Thank you, we'll get in touch soon.`;
  };

  return (
    <StyledContactForm>
      <h4>
        Please complete the form provided below or alternatively, you may
        contact us directly at info@enexsub.com
      </h4>
      <form ref={form} onSubmit={sendEmail}>
        <label>Name</label>
        <input
          type="text"
          onChange={handleInputChange}
          name="user_name"
          minLength={5}
        />
        <label>Email</label>
        <input type="email" onChange={handleInputChange} name="user_email" />
        <label>Message</label>
        <textarea name="message" onChange={handleInputChange} minLength={20} />
        <input
          disabled={disabledSend}
          id="submit-button"
          type="submit"
          value="Send"
        />
      </form>
    </StyledContactForm>
  );
};

export default ContactForm;

// Styles
const StyledContactForm = styled.div`

/* MOBILE */
font-family: "Share Tech", sans-serif;
width: auto;
height: 80vh;
color: rgba(180, 180, 180, 1);
opacity: 0.8;
transition: all 0.5s;
padding-top: 20px;
    
  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;


    input {
      font-family: "Share Tech", sans-serif;
      width: 100%;
      height: 35px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgba(215, 215, 215, 1);

      &:focus {
        border: 1px solid rgba(60, 60, 60, 1);
        transition: all 0.5s;
      }
    }


    textarea {
      font-family: "Share Tech", sans-serif;
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);
      
      &:focus {
        border: 1px solid rgba(60, 60, 60, 1);
        transition: all 0.5s;
      }
    }


    label {
      font-size: 0.8em;
      margin-top: 1rem;
      padding-bottom: 3px;
    }
    

    input[type="submit"] {
      font-size: 0.7em;
      margin-top: 2rem;
      cursor: pointer;
      background: rgb(0, 0, 0, 1);
      color: white;
      border: none;
    }

    input[type="submit"]:hover {
      filter: brightness(80%);
      transition: all 0.5s;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2), 0px 4px 20px rgba(0, 0, 0, 0.1), 0px 7px 8px rgba(0, 0, 0, 0.1);
      background: rgba(60, 60, 60, 1)
    }

    input[type="submit"]:disabled {
      background: rgba(150, 150, 150, 1);
    }

  }


/* DESKTOP */

  @media only screen and (min-width: 700px) {

font-family: "Share Tech", sans-serif;
margin-left: 35%;
margin-right: 35%;
align-self: center;
color: rgba(180, 180, 180, 1);
opacity: 0.8;
transition: all 0.5s;

    
  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    input {
      font-family: "Share Tech", sans-serif;
      width: 100%;
      height: 35px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgba(215, 215, 215, 1) ;
      background-color: white;
      transition: all 0.5s;


      &:focus {
        border: 1px solid rgba(60, 60, 60, 1);
        
      }
    }


    textarea {
      font-family: "Share Tech", sans-serif;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgba(215, 215, 215, 1);
      transition: all 0.5s;

      
      &:focus {
        border: 1px solid rgba(60, 60, 60, 1);
      }
    }


    label {
      margin-top: 1 rem;
    }
    

    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      border: none;
    }

    input[type="submit"]:hover {
      filter: brightness(80%);
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2), 0px 4px 20px rgba(0, 0, 0, 0.1), 0px 7px 8px rgba(0, 0, 0, 0.1);
      background: rgba(60, 60, 60, 1)
    }

    input[type="submit"]:disabled {
      background: rgba(150, 150, 150, 1);
    }
  }
`;
