import React from "react";
import { Navigation } from "../components/Navigation";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import "./Contact.css";

export const Contact = () => {
  return (
    <div className="page-wrapper">
      <div id="contact" className="content">
        <ContactForm />
      </div>
      <Footer />
    </div>
  );
};