import React from 'react';
import subtlePattern from '../assets/img5.png'; // Import the image
import Footer from "../components/Footer";

const Services = () => {
  return (
    <div className="page-container">
      <header className="App-header">
        {/* Navigation component */}
      </header>
      <div className="main-content">
        <div className="image-container">
          <img src={subtlePattern} alt="Subtle Pattern" />
        </div>
        <div className="text-column">
          <h2>Our Services</h2>
          <p className="description">
            At ENEXSUB, we offer a comprehensive range of 3D modeling and 2D drafting services tailored to meet your project's needs.
          </p>
          <ul className="services-list">
            <li>General arrangement drawings</li>
            <li>Assembly drawings</li>
            <li>Detailed Drawings</li>
            <li>Part Drawings</li>
            <li>Machining Drawings</li>
            <li>Lifting Arrangement Drawings</li>
            <li>Research and Development (R&D) Assistance</li>
            <li>Concept Design</li>
            <li>Cutting File Creation (DXF)</li>
            <li>Material Take-Off (MTO)</li>
          </ul>
          <button id="contact-button">Contact Us</button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Services;